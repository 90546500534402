(function ($) {

	// mobile menu	
	$('#main-menu').slb_mobile_drop_menu({
		breakpoint: 'lg'
	});

	// sticky bar
	$('#header').slb_sticky({
		breakpoints: {
			xs: {
				top_limit: 250,
			},
			md: {
				top_limit: 105,
			}
		},
	});

	if ($('.fontsize').length > 0) {
		$(".fontsize .inline").after($(".fontsize select"));
		$(".fontsize .inline").before($(".subtitle-design-3"));
	}

	//
	if ($('.fonttype').length > 0) {
		$(".fonttype .inline").before($(".subtitle-design-2"));
		$(".fonttype .inline").after($(".fonttype select"));
	}

	if ($('.sec-design-custom-bottom ').length > 0) {
		$(".sec-design-custom-bottom .row").after($(".wrapper-sendcustomdesign"));
	}

	if ($('#product-images').length > 0) {
		var productswiper = new Swiper('#product-images', {
			pagination: '#product-images .swiper-pagination',
			nextButton: '#product-images .swiper-button-next',
			prevButton: '#product-images .swiper-button-prev',
			onlyExternal: true
		});

		var set_thumb = function () {
			var target = productswiper.realIndex;

			$('#product-image-thumbs a').removeClass('active animateline');
			$('#product-image-thumb-' + target).addClass('active animateline');

		};


		$('#product-image-thumbs a').click(function (e) {
			var target = $(this).attr('data-num');
			productswiper.slideTo(target);
			$('#product-image-thumbs a').removeClass('animateline');

			setTimeout(function () {
				$('#product-image-thumb-' + target).addClass('animateline');
			}, 150);

		});


		productswiper.on('onSlideChangeStart', set_thumb);
		set_thumb();
	}

	// contact msg

	function formMessage() {

		if ($("#contacto").length > 0) {
			$("#contacto .form-description").after($("#contacto .msg-contactsend"));

			var form = $('#contactstamp');

			form.submit(function (e) {
				e.preventDefault();
				$('#contacto').addClass("onload");
				$.ajax({
					url: form.attr('action'),
					type: form.attr('method'),
					dataType: 'html',
					data: form.serialize(),
					success: function (result) {
						$('#contacto .form-field.txt, #contacto .buttons').hide();
						$('#contacto').removeClass("onload");
						$('.msg-contactsend').addClass("sendsuccess");
					}
				});
			});
		}
	}


	function pgcart() {
		$(".lb-subtotal label").after($('<span class="valtotal">$98,000.00</span>'));
		$(".lb-shippingstandar .form-content").append($('<span class="shippingval">' + $(".envio-days").text() + '</span>'));
		$(".lb-addcuppon input").before($(".lb-addcuppon label"));
		$(".lb-totalbuy .inline").append('<span class="totalnumber"></span>');
		$(".lb-costshipping label").after($('<span class="valcostoenvio">' + $(".costo-envio").text() + '</span>'));
	}

	function bannerPage() {

		setTimeout(function () {
			$(".sec-page-banner h1").addClass("active animated slideInLeft");
		}, 100);

		setTimeout(function () {
			$(".sec-page-banner h2").addClass("active animated slideInLeft");
		}, 600);

	}
	//

	function productPreview() {

		$(".faddcart .textarea textarea").keyup(function () {
			var textCustom = $(this);
			$("#box-design-edit p").text(textCustom.val());
			// $("#box-design-edit").html(textCustom.val().replace(/(\r\n|\n)/g, "<br/>"));
		});

	}

	function customDesignForm() {
		if ($(".pg-custom-design").length > 0) {
			$("#filescustomer").attr("enctype", "multipart/form-data");
		}
	}

	function linkCartHover() {
		$(".cart-top").hover(
			function () {
				setTimeout(function () {
					$(".header").addClass("oncartheader");
				}, 95);
			},
			function () {
				$(".header").removeClass("oncartheader");
			}
		);
	}

	function labelAfterInput() {
		if ($("#contactstamp, #cutomdesign").length > 0) {
			$(".content-form .in-il").each(function () {
				$($(this).find(".form-content input")).before($(this).find(".form-content label"));
			});

			$(".content-form .in-il-check").each(function () {
				$($(this).find(".form-content .content-radiobutton")).before($(this).find(".form-content .inline"));
			});

			$(".oncustomdesignct .content-radiobutton").before($(".oncustomdesignct .form-content .inline"));

		}
	}

	function cleanImgHero() {
		$(".sec-page-banner .imgfull img").attr("src", " ");
	}


	function bannerStoreBreak() {
		if ($(".imgpoints-store").length > 0) {
			var arrImg = [];
			var urlImg = $(".sec-page-banner .imgfull img").attr("src");
			arrImg = urlImg.split("/");
			arrImg.shift();
			arrImg.pop();

			var pathInit = "/" + arrImg.join("/") + "/";

			if ($(window).width() < 769) {
				cleanImgHero();
				$(".sec-page-banner .imgfull img").attr("src", pathInit + $(".imgpoints-store").attr("data-imghero-b1"));
				$(".banner-store .imgfull").addClass("b1store");
			} else {
				cleanImgHero();
				$(".sec-page-banner .imgfull img").attr("src", pathInit + $(".imgpoints-store").attr("data-imghero-dk"));
				$(".banner-store .imgfull").removeClass("b1store");
			}
		}

	}
	// termina banner tienda

	function bannerBreak() {

		if ($(".imgpoints").length > 0) {
			var arrImg = [];
			var urlImg = $(".sec-page-banner .imgfull img").attr("src");
			arrImg = urlImg.split("/");
			arrImg.shift();
			arrImg.pop();

			var pathInit = "/" + arrImg.join("/") + "/";

			if ($(window).width() < 576) {
				cleanImgHero();
				$(".sec-page-banner .imgfull img").attr("src", pathInit + $(".imgpoints").attr("data-imghero-b1"));
			} else if ($(window).width() >= 576 && $(window).width() < 768) {
				cleanImgHero();
				$(".sec-page-banner .imgfull img").attr("src", pathInit + $(".imgpoints").attr("data-imghero-b2"));
			} else if ($(window).width() >= 768 && $(window).width() < 800) {
				cleanImgHero();
				$(".sec-page-banner .imgfull img").attr("src", pathInit + $(".imgpoints").attr("data-imghero-b3"));
			} else if ($(window).width() >= 992 && $(window).width() < 1500) {
				cleanImgHero();
				$(".sec-page-banner .imgfull img").attr("src", pathInit + $(".imgpoints").attr("data-imghero-b4"));
			} else {
				cleanImgHero();
				$(".sec-page-banner .imgfull img").attr("src", pathInit + $(".imgpoints").attr("data-imghero-dk"));
			}

		}

	}

	function carruselCustomer() {

		if ($('#stamp-slider-h .swiper-slide').length > 1) {
			var homeswiper = new Swiper('#stamp-slider-h', {
				direction: 'horizontal',
				slidesPerView: 1,
				//pagination: '.swiper-pagination',
				paginationHide: true,
				paginationClickable: true,
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				autoplay: false,
			});
		}
	}

	function carruselProducts() {

		if ($('#product-slider-h .swiper-slide').length > 1) {
			var homeswiper = new Swiper('#product-slider-h', {
				direction: 'horizontal',
				slidesPerView: 4,
				spaceBetween: 16,
				paginationHide: true,
				paginationClickable: true,
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				autoplay: false,
				breakpoints: {
					// when window width is >= 320px
					320: {
					  slidesPerView: 1,
					  spaceBetween: 20
					},
					// when window width is >= 480px
					480: {
					  slidesPerView: 1,
					  spaceBetween: 30
					},
					// when window width is >= 640px
					640: {
					  slidesPerView: 4,
					  spaceBetween: 40
					}
				  }
			});
		}
		if ($('#product-slider-h-1 .swiper-slide').length > 1) {
			var homeswiper = new Swiper('#product-slider-h-1', {
				direction: 'horizontal',
				slidesPerView: 4,
				spaceBetween: 16,
				paginationHide: true,
				paginationClickable: true,
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				autoplay: false,
			});
		}
		if ($('#product-slider-h-2 .swiper-slide').length > 1) {
			var homeswiper_2 = new Swiper('#product-slider-h-2', {
				direction: 'horizontal',
				slidesPerView: 4,
				spaceBetween: 16,
				paginationHide: true,
				paginationClickable: true,
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				autoplay: false,
			});
		}
		if ($('#product-slider-h-3 .swiper-slide').length > 1) {
			var homeswiper_3 = new Swiper('#product-slider-h-3', {
				direction: 'horizontal',
				slidesPerView: 4,
				spaceBetween: 16,
				paginationHide: true,
				paginationClickable: true,
				nextButton: '.swiper-button-next',
				prevButton: '.swiper-button-prev',
				autoplay: false,
			});
		}
	}


	function tallaCustomDesignPg() {


		$(".product .selectsize .group-field").each(function () {
			var classInput = $(this).find("label").attr("value");
			$(this).addClass("talla-" + classInput);
		});

		$(".product-cat-bebes .selectsize .group-field").eq(0).addClass("noshow");
		$(".product-cat-ninos .selectsize .group-field").eq(0).addClass("noshow");

		// $( ".product-cat-bebes .selectsize .group-field" ).eq(0).addClass("noshow");
		// $( ".product-cat-ninos .selectsize .group-field" ).eq(0).addClass("noshow");
		// $( ".product-cat-ninos .selectsize .group-field" ).eq( 13 ).hide();
		// $( ".product-cat-ninos .selectsize .group-field" ).eq( 14 ).hide();
		// $( ".product-cat-ninos .selectsize .group-field" ).eq( 15 ).hide();
		//
		// $( ".cat-tienda-bebes-mameluco .selectsize .group-field" ).eq( 13 ).show();
		// $( ".cat-tienda-bebes-mameluco .selectsize .group-field" ).eq( 14 ).show();
		// $( ".cat-tienda-bebes-mameluco .selectsize .group-field" ).eq( 15 ).show();


		$(".selecttipersona label").click(function (e) {
			e.preventDefault();
			var valLab2 = $(this).attr("value");

			valLab2 = valLab2.toLowerCase();
			// mostrar solo cuelllo U para bebes y niños
			if (valLab2 == "bebes" || valLab2 == "ninos") {
				$(".selecttipocuello .group-field").eq(1).hide();
			}
			//

			$(this).parent(".group-field").find("input").prop("checked", true);
			$(".selecttipersona .group-field").removeClass("active");
			$(".selecttipersona input").val("");
			$(".oncustomdesignct").attr("id", "");
			$(this).parent(".group-field").addClass("active");
			var valField = $(this).parent(".group-field").find("input").val();

			$(".selecttipersona label").val(valField);
			$(".oncustomdesignct").attr("id", valLab2);
			//.selectsize = valField;

			$("#ninos .group-field").eq(11).hide();
			$("#ninos .group-field").eq(12).hide();
			$("#ninos .group-field").eq(13).hide();
			$("#ninos .group-field").eq(14).hide();
			$("#ninos .group-field").eq(15).hide();

			$("#bebes .group-field").eq(11).show();
			$("#bebes .group-field").eq(12).show();
			$("#bebes .group-field").eq(13).show();
			$("#bebes .group-field").eq(14).show();
			$("#bebes .group-field").eq(15).show();
			$(".selecttipersona input").val(valLab2);

			// $( ".product-cat-ninos .selectsize .group-field" ).eq( 13 ).hide();
			// $( ".product-cat-ninos .selectsize .group-field" ).eq( 14 ).hide();
			// $( ".product-cat-ninos .selectsize .group-field" ).eq( 15 ).hide();

		});
	}

	if ($("#home-slider .swiper-slide").length > 0) {
		$("#home-slider .swiper-slide").eq(2).addClass("center-slde-home");
	}

	bannerPage();
	productPreview();
	formMessage();
	pgcart();
	customDesignForm();
	linkCartHover();
	labelAfterInput();
	carruselCustomer();
	carruselProducts();
	tallaCustomDesignPg();


	if ($(".imgpoints").length > 0) {
		bannerBreak();

		$(window).resize(function () {
			bannerBreak();
		});
	} // slider is

	// banner tienda

	if ($(".imgpoints-store").length > 0) {
		bannerStoreBreak();

		$(window).resize(function () {
			bannerStoreBreak();
		});
	} // slider is
	// termina banner tienda


	var getParams = function (url) {
		var params = {};
		var parser = document.createElement('a');
		parser.href = url;
		var query = parser.search.substring(1);
		var vars = query.split('&');
		for (var i = 0; i < vars.length; i++) {
			var pair = vars[i].split('=');
			params[pair[0]] = decodeURIComponent(pair[1]);
		}
		return params;
	};

}(jQuery));

// drag file custom
$(document).ready(function () {

	$(".dz-default.dz-message").text("Arrastra o selecciona un archivo");
	$(".dz-default.dz-message").after($('<p class="button-outline">Buscar</p>'));
	$(".dz-default.dz-message").after($('.content-top .specupload'));


	// function initPlayeraSimpleSpotyA() {   
	// 	var color = $(".clothescolor .active input").val();
	// 	var spoty = $("#enablespotify").val();
	// 	var comb = color+ "-" +spoty;
	// 	var imgShow = "";
	// 	console.log("la combinbacion: " + comb); 

	// 	switch(comb) {
	// 	  case "blanco-off":          
	// 	  imgShow = $("#imgplauser").attr("data-bl_fr");          
	// 		break;
	// 	  case "blanco-on":
	// 		imgShow = $("#imgplauser").attr("data-bl_fr_cod");          
	// 		break;
	// 	  case "negro-off":
	// 		imgShow = $("#imgplauser").attr("data-ne_fr");          
	// 		break;

	// 	  case "negro-on":
	// 		imgShow = $("#imgplauser").attr("data-ne_fr_cod");          
	// 	  break;

	// 	  default:
	// 		console.log("5")
	// 	}     

	// 	$("#imgplauser").attr("src", imgShow);

	//   }


	if (window.location.href.indexOf('?color=negro') > -1 || $(".pg-colornegro").length > 0) {
		$(".clothescolor .group-field:nth-child(2) label").trigger("click");
	}
	$(".selecttipersona .group-field:eq(0) label").trigger("click");

	// color iniciar en playeras V2
	// if($(".prod-simple.icolor-negro").length > 0) {
	// 	$(".prod-simple .typecuello .group-field:nth-child(2) label").trigger("click");
	// 	console.log("incia puto color NEROOO")
	//   }

	//   if($(".prod-simple.icolor-blanco").length > 0 ) {
	// 	$(".prod-simple .typecuello .group-field:nth-child(1) label").trigger("click");
	// 	console.log("incia puto color BLANCOOO")
	//   }
	//initPlayeraSimpleSpotyA();
});

// Para crear unos cubtios en el formulario
// $('#contact .container-fluid .row').append('<div class="drops"> <div class="drop drop-1"></div><div class="drop drop-2"></div><div class="drop drop-3"></div><div class="drop drop-4"></div><div class="drop drop-5"></div> </div>')