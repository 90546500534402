(function($) {

  var productAdd = {};

  function initProduct() {
    $(".selectsize .group-field").first().addClass("active");
    $(".selectsize .group-field").first().find("input").prop("checked", true);
    $(".clothescolor .group-field").first().addClass("active");
    $(".clothescolor .group-field").first().find("input").prop("checked", true);
    $(".posplayera .group-field").first().addClass("active");
    $(".posplayera .group-field").first().find("input").prop("checked", true);
    $(".textcolor .group-field").first().addClass("active");
    $(".textcolor .group-field").first().find("input").prop("checked", true);
    $(".typematerial .group-field").first().addClass("active");
    $(".typematerial .group-field").first().find("input").prop("checked", true);
    $(".imgproduct input").val($(".product-optional").attr("data-img-thumb-white"));
    $(".selecttipocuello .group-field").first().addClass("active");
    $(".selecttipocuello .group-field").first().find("input").prop("checked", true);

  }

  function imgPrevChange(colorT, poscT) {
    var imgProduct = "data-img-thumb-black";

    var colorpos = poscT + "-" + colorT;

    if($(".clothescolor .group-field.active input").val() == "blanco") {
        var dataImgTshirt = "data-white";
    } else {
        var dataImgTshirt = "data-black";
    }

    if(colorpos  == "atras-blanco") {
        dataImgTshirt = "data-white-back";
        imgProduct = "data-img-thumb-white";
    } else if (colorpos  == "atras-negro") {
        dataImgTshirt = "data-black-back";
        imgProduct = "data-img-thumb-black";
    } else if (colorpos  == "frente-negro") {
        dataImgTshirt = "data-black";
        imgProduct = "data-img-thumb-black";
    } else {
        // colorpos  == "frente-blanco"
        dataImgTshirt = "data-white";
        imgProduct = "data-img-thumb-white";
    }

    $(".previmg").attr("src", $(".previmg").attr(dataImgTshirt));

    $(".imgproduct input").val($(".product-optional").attr(imgProduct));

  }

  function selectSize() {
    $(".selectsize label").click(function(e) {
      e.preventDefault();
      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".selectsize .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("input").val();
      productAdd.selectsize = valField;
    });
  }

  // spotify code playeras
  function initPlayeraSimpleSpoty() { 
    
    var statusSpoti = $("#enablespotify").val();
    var selCuello = $("#can-cuello").attr("data-status");
    var selColor = $("#can-color").attr("data-status");
    var defaultPrev = $("#product-images-cus").attr("data-default");

    $("." + defaultPrev).addClass("active");

    // if(selCuello == "n" && selColor == "n") {

    //   if(statusSpoti == "on") {
    //     $("#opc1").removeClass("active");
    //     $("#opc2").addClass("active");
    //   } else {
    //     $("#opc1").addClass("active");
    //     $("#opc2").removeClass("active");
    //   }
                  
    // } else {
    //   console.log("varios colores")
    // }
      
    // var color = $(".clothescolor .active input").val();
    // var spoty = $("#enablespotify").val();
    // var comb = color+ "-" +spoty;
    // var imgShow = "";
    // console.log("la combinbacion: " + comb); 

    // switch(comb) {
    //   case "blanco-off":          
    //   imgShow = $("#imgplauser").attr("data-bl_fr");          
    //     break;
    //   case "blanco-on":
    //     imgShow = $("#imgplauser").attr("data-bl_fr_cod");          
    //     break;
    //   case "negro-off":
    //     imgShow = $("#imgplauser").attr("data-ne_fr");          
    //     break;

    //   case "negro-on":
    //     imgShow = $("#imgplauser").attr("data-ne_fr_cod");          
    //   break;
        
    //   default:
    //     console.log("5")
    // }     
    
    // $("#imgplauser").attr("src", imgShow);

  }

  function changePlayeraPreview(){

    
    var userCuello= "";


      if($(".typecuello .group-field.active label").attr("value") == "cuello-u"){
        var userCuello= "-u";
      } else  if($(".typecuello .group-field.active label").attr("value") == "cuello-v") {
        var userCuello= "-v";
      } else {
        //var userCuello= "-lalal"; 
        var userCuello=  ($("#can-cuello").attr("data-cuello") == "cuello-v")? "-v":"-u";
      }



    var userColor =  $(".clothescolor .group-field.active label").attr("value");
    var userSpotify =  ($("#enablespotify").attr("value") == "on")?"-spotify":"";

  
    // termina de colocar el color

    var comb = userColor + "-frente" + userCuello + userSpotify;

    if($("."+comb).length > 0){
      $(".imgp ").removeClass("active");
      $("."+comb).addClass("active");
    }
    
    // console.log(userColor);
    console.log(userCuello);
    // console.log(userSpotify);
    console.log("iniciando cambios");
    console.log(comb);
    
    //negro-frente-spotify
    //negro-frente-u-spotify
  }

  ///Click sobre spoty Code
  $(".custom-control-label").click(function() { 

    $(this).toggleClass("sptifyon");
    $("main").toggleClass("mainsptifyon");

    $(".namesong").toggleClass("showsong");
    $(".prod-simple").toggleClass("product-show-song");
    
    if($("#enablespotify").val() == "off") {
      $("#enablespotify").val("on");                    
    } else {
      $("#enablespotify").val("off");
    }
    console.log("cambia spoty");

    changePlayeraPreview();
   
  });

  function selectClothescolor() {
    $(".clothescolor label").click(function(e) {
      e.preventDefault();
      
      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".clothescolor .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("label").attr("value");
      productAdd.clothescolor = valField;
      var imgThumb = (valField == "negro")?"data-img-thumb-black":"data-img-thumb-white";

      $(".imgproduct input").val($(".product-optional").attr(imgThumb));

      imgPrevChange(valField, $(".posplayera .group-field.active label").attr("value"));

      console.log("actualiza el color  de playera prev");
      changePlayeraPreview();

    });
  }

  function selectCuello() {
    $(".selecttipocuello label").click(function(e) {
      e.preventDefault();
      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".selecttipocuello .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("input").val();
      productAdd.selecttipocuello = valField;
    });
  }

  function selectCuelloOtrasIMG() {
   
    $(".typecuello label").click(function(e) {
      e.preventDefault();
      // $(".typecuello .group-field:nth-child(1)").removeClass("active");
      // $(".typecuello .group-field:nth-child(2)").removeClass("active");
      $(".typecuello .group-field").removeClass("active");
      $(this).parent(".group-field").find("input").prop("checked", true);
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("input").val();
      productAdd.selecttipocuello = valField;
      console.log("Cambio de Cuelllo");
      changePlayeraPreview()
    });
  }

  function selectTextcolor() {
    $(".textcolor label").click(function(e) {
      e.preventDefault();

      $(".material-description .itemc").removeClass("initctxt");
      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".textcolor .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("label").attr("value").split("-");
      productAdd.textcolor = "#" + valField[1];
      $("#box-design-edit").css("color", productAdd.textcolor);
      // añadir clase  para saber que  div  en tipo de material mostrar
      $(".material-description").removeClass("opc1");
      $(".material-description").removeClass("opc2");
      $(".material-description").removeClass("opc3");
      $(".material-description").removeClass("opc4");
      $(".material-description").removeClass("opc5");
      $(".material-description").removeClass("opc6");
      $(".material-description").addClass(valField[0]);
      //
    });
  }
  //

  function selectPostShirt() {
    $(".posplayera label").click(function(e) {
      e.preventDefault();

      $(".container-product").removeClass("pos-atras");
      $(".container-product").removeClass("pos-frente");

      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".posplayera .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("label").attr("value");
      productAdd.posplayera = valField;
      $(".container-product").addClass("pos-" + valField);
      imgPrevChange($(".clothescolor .group-field.active label").attr("value"), valField);
      //test
      $(".product .custom-design").addClass("oncustomdesign");

    });
  }

  function selectTypematerial() {

    $(".product-wrapper-content").addClass("reflejante-plata");

    $(".typematerial label").click(function(e) {
      e.preventDefault();

      $(".material-description").removeClass("opc1");
      $(".material-description").removeClass("opc2");
      $(".material-description").removeClass("opc3");
      $(".material-description").removeClass("opc4");
      $(".material-description").removeClass("opc5");
      $(".material-description").removeClass("opc6");

      $(this).parent(".group-field").find("input").prop("checked", true);
      $(".typematerial .group-field").removeClass("active");
      $(this).parent(".group-field").addClass("active");
      var valField = $(this).parent(".group-field").find("input").val();
      productAdd.typematerial = valField;
        // añadir clase
      valField = valField.replace(" ", "-");
      valField = valField.toLowerCase();

      $(".product-wrapper-content").removeClass("reflejante-plata");
      $(".product-wrapper-content").removeClass("terciopelo");
      $(".product-wrapper-content").removeClass("vinil-mate");
      $(".product-wrapper-content").removeClass("vinil-brillante");
      $(".product-wrapper-content").addClass(valField);

      $(".textcolor .group-field:eq(0)").addClass("onfirstx");

      $(".reflejante-plata .textcolor .group-field").eq( 4 ).find("label").trigger( "click" );

      $(".terciopelo .textcolor .group-field").eq(0).find("label").trigger( "click" );

      $(".vinil-mate .textcolor .group-field").eq(0).find("label").trigger( "click" );

      $(".vinil-brillante .textcolor .group-field").eq(0).find("label").trigger( "click" );

    });
  }

  function validaProductForm() {

      if($(".fontsize select").val() == "default") {
        $('.fontsize .form-content').after('<p class="error">Selecciona un tamaño de texto</p>');
      }


    if($(".fonttype select").val() == "default") {
      $('.fonttype .form-content').after('<p class="error">Selecciona una tipografía</p>');
    }

    if($(".textarea  textarea").val() == "") {
      $('.textarea .form-content').after('<p class="error">Ingresa tu mensaje</p>');
    }

  }

  function addProductCart() {
    //
    $(".product-optional .addcart, .product-optional .addcontinue").click(function() {

        $('.textarea .error, .fonttype .error').remove();

        validaProductForm(); 

        if($(".fontsize select").val() != "defaul" && $(".fonttype select").val() != "default" && $(".textarea  textarea").val() != "") {

          if( $(this).attr("href") == "#addcontinue") {
              $(".fcontinuebuy input").val("true");
          }

          var editURl = window.location.search.replace("?", "").split("&");

          $(".feditproduct input").val(editURl);
          var estilocuello = $(".product-title .spec1").text();
          $(".estilocuello input").val(estilocuello);
          var productId = window.location.pathname.split("/");
          var today = new Date();
          var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
          var time = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
          var fulltitle = "";

          if(productId[0] == ""){
            productId.shift();
          }

          var nameProduct = $(".product-title").text().split(" ");

          if(editURl[1] == "edit=true" && editURl[0] != ""){
            var idEdit = editURl[0];
            var idEditArr = idEdit.split("=");
            fulltitle = idEditArr[1];
          } else {
            fulltitle = productId.join("_") + "_time-" + date + time;
          }

          $(".nameproduct input").val($(".ptitle").text());
          $(".fullnameproduct input").val(fulltitle);
          $(".frate input").val($(".product-optional").attr("data-rate"));
          $(".fqty input").val("1");

          imgPrevChange($(".clothescolor .active input").val(), $(".posplayera .group-field.active label").attr("value"));

          $("#submit-addcart").trigger("click");

        }//end empty

    });
  }


  function selectFontType(){

    if($(".subtitle-design-2").length > 0) {
      $(".subtitle-design-2").attr("id", "defaultfont");
    }

    $('.fonttype select').change(function(){
        $(".subtitle-design-2").attr("id", $(this).find("option:selected").attr('value'));
        $("#box-design-edit").removeClass();
        $("#box-design-edit").addClass($(this).find("option:selected").attr('value'));
    });

  }


  function selectFontSize(){

    if($(".subtitle-design-3").length > 0) {
      // $(".subtitle-design-3").attr("id", "defaultfont");

      $(".subtitle-design-3").attr("id", $(".subtitle-design-3 option:selected").attr('value'));

    }

    $('.fontsize select').change(function(){
        $(".subtitle-design-3").attr("id", $(this).find("option:selected").attr('value'));
        var sizeDef = $(this).find("option:selected").attr('value');
         // sizeDef = sizeDef.replace("opc-", "");
         // productAdd.sizeText = sizeDef;

         $(".wrapper-product-bootom").removeClass("font-size-default");
         $(".wrapper-product-bootom").removeClass("font-size-chica");
         $(".wrapper-product-bootom").removeClass("font-size-mediana");
         $(".wrapper-product-bootom").removeClass("font-size-grande");
        $(".wrapper-product-bootom").addClass("font-size-" + sizeDef);
    });

  }

  function valuePrev(wordPrev) {
    var item = wordPrev.split(":");
    return item[1];
  }

  function initPechoEspalda() {
      if($(".custom-design").length > 0) {
          $(".posplayera .form-content" ).after($(".custom-design"));
      }
  }


  function initEditProduct() {
      // trabajando

    if($("body.product").length > 0 && $(".product-optional").attr("data-product-preview") != "[]" && $(".product-optional").attr("data-product-preview") != "") {

      var prevProduct = JSON.parse($(".product-optional").attr("data-product-preview"));
      console.log(prevProduct);

      $(".selectsize input[value='" + prevProduct.fsize + "']").prop("checked", true);
      $(".selectsize input[value='" + prevProduct.fsize + "']").parent(".group-field").addClass("active");

      $(".fontsize select option[value='" + prevProduct.ffontsize + "']").attr("selected",true);
      $(".wrapper-product-bootom").addClass("font-size-" + prevProduct.ffontsize);

      $(".product-wrapper-content").removeClass("reflejante-plata");
      $(".product-wrapper-content").removeClass("terciopelo");
      $(".product-wrapper-content").removeClass("vinil-mate");
      $(".product-wrapper-content").removeClass("vinil-brillante");
      $(".product-wrapper-content").addClass(prevProduct.ftypematerial);
      //
      $(".reflejante-plata .textcolor .group-field.active label").trigger( "click" );
      $(".terciopelo .textcolor .group-field.active label").trigger( "click" );
      $(".vinil-mate .textcolor .group-field.active label").trigger( "click" );
      $(".vinil-brillante .textcolor .group-field.active label").trigger( "click" );
      //

      $(".clothescolor input[value='" + prevProduct.fcolorclothes + "']").prop("checked", true);
      $(".clothescolor input[value='" + prevProduct.fcolorclothes + "']").parent(".group-field").addClass("active");

      $(".posplayera input[value='" + prevProduct.ftypeposicion + "']").prop("checked", true);
      $(".posplayera input[value='" + prevProduct.ftypeposicion + "']").parent(".group-field").addClass("active");
      $(".fonttype select option[value='" + prevProduct.ffonttype + "']").attr("selected",true);
      $(".subtitle-design-2").attr("id", prevProduct.ffonttype);
      $(".textcolor input[value='" + prevProduct.fcolortext + "']").prop("checked", true);
      $(".textcolor input[value='" + prevProduct.fcolortext + "']").parent(".group-field").addClass("active");

      $("#box-design-edit").css("color", prevProduct.fcolortext);

      $(".fieldtext textarea").val(prevProduct.ftext);
      $(".typematerial input[value='" + prevProduct.ftypematerial + "']").prop("checked", true);
      $(".typematerial input[value='" + prevProduct.ftypematerial + "']").parent(".group-field").addClass("active");
      $("#box-design-edit p").text(prevProduct.ftext);

      // prevProduct.rotacion_frase
      // prevProduct.posicion_frase
      $("#box-design-edit .text").css("transform", prevProduct.rotacion_frase);
      $("#box-design-edit .text").css("transform", "rotate(45deg)");


      //fsize
    } else {
      initProduct();
    }
  }



  function cambiarSlideProducSimple() {
    $(".prod-simple .faddcart .posplayera .group-field:eq(0)").click(function(e) {
      e.preventDefault();
      $(".swiper-button-prev").trigger( "click" );
    });

    $(".prod-simple .faddcart .posplayera .group-field:eq(1)").click(function(e) {
      e.preventDefault();
      $(".swiper-button-next").trigger( "click" );
    });
  }





selectSize();
selectClothescolor();
selectTextcolor();
selectTypematerial();
selectFontType();
addProductCart();
selectPostShirt();
initEditProduct();
selectFontSize();
selectCuello();
selectCuelloOtrasIMG();
initPechoEspalda();
cambiarSlideProducSimple();
initPlayeraSimpleSpoty();


}(jQuery));


$(document).ready(function() {

    $(".reflejante-plata .textcolor .group-field.active label").trigger( "click" );
    $(".terciopelo .textcolor .group-field.active label").trigger( "click" );
    $(".vinil-mate .textcolor .group-field.active label").trigger( "click" );
    $(".vinil-brillante .textcolor .group-field.active label").trigger( "click" );

    
    // dejar or default un cuello si es que se puede seleccionar

    if($(".cancuello").length > 0 && $(".icuello-v").length > 0) {
      $(".typecuello .group-field:nth-child(1) label").trigger("click");
    }

    if($(".cancuello").length > 0 && $(".icuello-u").length > 0) {
      $(".typecuello .group-field:nth-child(2) label").trigger("click");
    }

    // color iniciar en playeras V2
  	if($(".prod-simple.icolor-negro").length > 0) {
      $(".prod-simple .clothescolor .group-field:nth-child(2) label").trigger("click");
      console.log("incia NEfroa")
	  }
  
	  if($(".prod-simple.icolor-blanco").length > 0 ) {
      $(".prod-simple .clothescolor .group-field:nth-child(1) label").trigger("click");
      console.log("incia  BLAncoo")
	  }

    
 

  //
  // if( $(".prod-simple.cuello-vcuello-u").length > 0 || $(".prod-simple.cuello-ucuello-v").length > 0)  {

  // }

  // //
  // if( $(".prod-simple.cuello-u").length > 0)  {

  // }
  
  // //
  // if( $(".prod-simple.cuello-v").length > 0)  {

  // }

  // // colores
  // if( $(".icolor-negro").length > 0)  {

  // }

  


  

});
